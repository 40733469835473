import * as React from "react";
import { Box } from "theme-ui";

import Column from "components/layout/Column";
import Container from "components/layout/Container";
import Row from "components/layout/Row";
import Image from "components/media/Image";
import Heading from "components/typography/Heading";
import Text from "components/typography/Text";
import Modal from "components/Modal";
import avoidOrphan from "lib/avoidOrphan";
import PropTypes from "lib/propTypes";
import textareaToArray from "lib/textareaToArray";

function BioModal({
  close,
  description,
  firstName,
  insightsDescription,
  insightsRoles,
  insightsWheel,
  lastName,
  logoIcon,
  title,
  visible,
}) {
  return (
    <Modal bordered {...{ close, logoIcon, visible }}>
      <Container thin pb={80}>
        <Box mb="xs" sx={{ borderTop: `1px solid` }} />
        <Heading as="h2" variant="h3" mb={[0, 0]}>
          {firstName} {lastName}
        </Heading>
        {title && (
          <Heading as="p" variant="h6">
            {title}
          </Heading>
        )}
        <Text variant="l">{description}</Text>
        {insightsWheel.filename && (
          <Row as="aside">
            <Column span={[6, 3, 4]}>
              {insightsRoles && (
                <Text as="p" variant="l">
                  <Text as="b" variant="l" sx={{ fontWeight: `normal` }}>
                    {firstName} is:
                  </Text>
                  {` `}
                  {textareaToArray(insightsRoles).join(`/`)}
                </Text>
              )}
              {insightsDescription && (
                <Text as="p" variant="m">
                  {avoidOrphan(insightsDescription)}
                </Text>
              )}
            </Column>
            <Column span={[6, 3, 2]}>
              <Image
                src={insightsWheel.filename}
                alt={insightsWheel.alt}
                sx={{ display: `block`, margin: `auto`, width: 320 }}
              />
            </Column>
          </Row>
        )}
      </Container>
    </Modal>
  );
}

BioModal.propTypes = {
  close: PropTypes.func.isRequired,
  description: PropTypes.richText,
  firstName: PropTypes.string.isRequired,
  insightsDescription: PropTypes.string,
  insightsRoles: PropTypes.string,
  insightsWheel: PropTypes.asset,
  lastName: PropTypes.string.isRequired,
  logoIcon: PropTypes.asset.isRequired,
  title: PropTypes.string,
  visible: PropTypes.bool,
};

BioModal.defaultProps = {
  description: null,
  insightsDescription: null,
  insightsRoles: null,
  insightsWheel: {},
  title: null,
  visible: false,
};

export default BioModal;
